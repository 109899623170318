var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Delay" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeToastDelay) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Change to auto hide delay time via the ")]),
        _c("code", [_vm._v("auto-hide-delay")]),
        _c("span", [
          _vm._v(" prop (value is in milliseconds), which defaults to ")
        ]),
        _c("code", [_vm._v("5000")]),
        _c("span", [_vm._v(" (minimum value ")]),
        _c("code", [_vm._v("1000")]),
        _c("span", [
          _vm._v(
            "). Or, disable the auto-hide feature completely by setting the "
          )
        ]),
        _c("code", [_vm._v("no-auto-hide")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("true")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.$bvToast.show("example-toast5")
                }
              }
            },
            [_vm._v(" Delay ")]
          ),
          _c(
            "b-toast",
            {
              attrs: { id: "example-toast5", "auto-hide-delay": "10000" },
              scopedSlots: _vm._u([
                {
                  key: "toast-title",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-grow-1 align-items-center mr-25"
                        },
                        [
                          _c("b-img", {
                            staticClass: "rounded mr-2",
                            attrs: {
                              src: require("@/assets/images/logo/logo.png"),
                              height: "18",
                              width: "25",
                              alt: "Toast image"
                            }
                          }),
                          _c("strong", { staticClass: "mr-auto" }, [
                            _vm._v("Vue Admin")
                          ]),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("11 mins ago")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [_c("span", [_vm._v("Hello, world! This is a toast message.")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }