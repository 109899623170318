var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Advanced usage" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAdvance) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("When using the ")]),
        _c("code", [_vm._v("this.$bvToast.toast(...)")]),
        _c("span", [
          _vm._v(
            " method for generating toasts, you may want the toast content to be more than just a string message. As mentioned in the Toasts on demand section above, you can pass arrays of "
          )
        ]),
        _c("code", [_vm._v("VNodes")]),
        _c("span", [
          _vm._v(" as the message and title for more complex content.")
        ])
      ]),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(113, 102, 240, 0.15)",
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: { "400": true }
            }
          ],
          attrs: { variant: "outline-primary" },
          on: { click: _vm.popToast }
        },
        [_vm._v(" Show Toast with custom content ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }