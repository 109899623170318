var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Links" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLinks) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Optionally convert the toast body to a link (")]),
        _c("code", [_vm._v("<a>")]),
        _c("span", [_vm._v(") or ")]),
        _c("code", [_vm._v("<router-link>")]),
        _c("span", [_vm._v(" (or ")]),
        _c("code", [_vm._v("<nuxt-link>")]),
        _c("span", [
          _vm._v(
            ") via the href and to props respectively. When set, the entire toast body becomes a link."
          )
        ])
      ]),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(113, 102, 240, 0.15)",
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: { "400": true }
            }
          ],
          attrs: { variant: "outline-primary" },
          on: {
            click: function($event) {
              return _vm.toast()
            }
          }
        },
        [_vm._v(" Toast with link ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }